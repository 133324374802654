import React, {
    useState,
    useEffect,
    useContext,
    useImperativeHandle,
    useCallback,
    useMemo,
    forwardRef,
} from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { createStore, SVGElementType } from 'polotno/model/store';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { replaceColors, urlToString } from 'polotno/utils/svg';
import {
    SidePanel,
    TextSection,
    BackgroundSection,
    ElementsSection,
    UploadSection,
} from 'polotno/side-panel';
import { setUploadFunc } from 'polotno/config';
import { Workspace } from 'polotno/canvas/workspace';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';

import { useService as useImagesService } from '../services/Images';

import {
    Template,
    UpdateParams as TemplateUpdateParams,
    useService as useTemplatesService,
} from '../services/Templates';
import { Service as TemplatesService } from '../services/Templates';
import { SingleSessionService } from '../services/TemplateEditorSessions';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';
import { useCreateOrderOrigin } from '../context/CreateOrderOrigin';

import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

import { MergeVariableSection } from '../components/MergeVariablePanel';
import { MERGE_VARIABLE_IMAGE } from '../components/MergeVariableImageElement';
import { EditorImagesSection } from '../components/EditorImagesPanel';
import { GridSection } from '../components/GridPanel';
import { GUIDELINE } from '../components/GuidelineElement';
import { EmbedError } from './InvalidEmbeddedRoute';
import { APIRequestError } from '../services/util';
import { TRACKER_IMAGE } from '../components/TrackerTemplateElement';
import { TrackersSection } from '../components/TrackersPanel';

export enum EditorCollateral {
    LETTER = 'letter',
    POSTCARD_6X4 = 'postcard_6x4',
    POSTCARD_9X6 = 'postcard_9x6',
    POSTCARD_11X6 = 'postcard_11x6',
    'BIFOLD_SELF_MAILER_8.5x11' = 'bifold_self_mailer_8x11',
}

export enum EditorCollateralDestination {
    US_INTL = 'us_intl',
    UK = 'gb',
    CA = 'ca',
    AU = 'au',
}

export enum EditorTemplateSide {
    FRONT = 'front',
    BACK = 'back',
}

interface EditorFont {
    fontFamily: string;
    styles: Array<{
        src: string;
        fontStyle?: string;
        fontWeight?: string;
    }>;
    url: string;
}

interface EditorElementBase {
    x: number;
    y: number;
    rotation: number;
    name: string;

    width: number;
    height: number;

    opacity: number;

    blurEnabled: boolean;
    blurRadius: number;

    brightnessEnabled: boolean;
    brightness: number;

    shadowEnabled: boolean;
    shadowBlur: number;
    shadowColor: string;

    custom?: {
        skipHTML?: boolean;
    };

    id: string;
}

interface EditorTextElement extends EditorElementBase {
    type: 'text';

    text: string;
    placeholder: string;

    fontSize: number;
    fontFamily: string;
    fontStyle: 'normal' | 'italic';
    fontWeight: string;

    textDecoration: string;

    fill: string;
    align: string;

    strokeWidth: number;
    stroke: string;

    lineHeight: number;
    letterSpacing: number;
}

interface EditorImageElement extends EditorElementBase {
    type: 'image';

    src: string;

    cropX: number;
    cropY: number;
    cropWidth: number;
    cropHeight: number;

    borderColor: string;
    borderSize: number;

    flipX: boolean;
    flipY: boolean;

    cornerRadius: number;
}

interface EditorLineElement extends EditorElementBase {
    type: 'line';

    dash: number[];
    color: string;
}

type EditorSVGElement = Omit<EditorImageElement, 'type'> & {
    type: 'svg';
    keepRatio: boolean;
    colorsReplace: Record<string, string>;
};

interface EditorMergeVariableImageElement extends EditorElementBase {
    type: typeof MERGE_VARIABLE_IMAGE;

    mergeVar: string;

    transparency: number;
    maintainAspectRatio: boolean;
}

interface EditorTrackerElement extends EditorElementBase {
    type: typeof TRACKER_IMAGE;

    trackerID: string;
    size: number;

    transparency: number;
}

// NOTE (Nolan): Seems that the children cannot be grouped elements,
// but we'll assume it may become possible.
// Also, while there are other properties like "opacity",
// it appleis directly to the children and not to the group
interface EditorGroupElement {
    name: string;
    type: 'group';
    children: EditorElement[];
}

type EditorElement =
    | EditorTextElement
    | EditorImageElement
    | EditorSVGElement
    | EditorLineElement
    | EditorMergeVariableImageElement
    | EditorGroupElement
    | EditorTrackerElement;

interface EditorPage {
    id: string;
    background: string;
    children: EditorElement[];
}

interface EditorData {
    width: number;
    height: number;
    fonts: EditorFont[];
    pages: EditorPage[];
}

interface RenderData {
    isPostcardBack: boolean;
    urlPrimaryUS: string;
    urlSecondaryUS: string;
    xPrimaryValueUS: number;
    yPrimaryValueUS: number;
    widthPrimaryValueUS: number;
    heightPrimaryValueUS: number;
    xSecondaryValueUS: number;
    ySecondaryValueUS: number;
    widthSecondaryValueUS: number;
    heightSecondaryValueUS: number;
    urlPrimaryCA: string;
    urlSecondaryCA: string;
    xPrimaryValueCA: number;
    yPrimaryValueCA: number;
    widthPrimaryValueCA: number;
    heightPrimaryValueCA: number;
    xSecondaryValueCA: number;
    ySecondaryValueCA: number;
    widthSecondaryValueCA: number;
    heightSecondaryValueCA: number;
    urlPrimaryUK: string;
    xPrimaryValueUK: number;
    yPrimaryValueUK: number;
    widthPrimaryValueUK: number;
    heightPrimaryValueUK: number;
}

const PIXELS_PER_INCH = 96;
const MAX_DATA_URL_SIZE = 5 * 1024;

const isPostcardCollateral = (col: EditorCollateral) => {
    switch (col) {
        case EditorCollateral.POSTCARD_6X4:
        case EditorCollateral.POSTCARD_9X6:
        case EditorCollateral.POSTCARD_11X6:
            return true;
        default:
            return false;
    }
};

const SelectDest = ({
    value,
    setValue,
}: {
    value: EditorCollateralDestination;
    setValue: (v: EditorCollateralDestination) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Collateral Destination</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorCollateralDestination)}
            >
                <FormControlLabel
                    value={EditorCollateralDestination.US_INTL}
                    control={<Radio color="primary" />}
                    label="US & International"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.UK}
                    control={<Radio color="primary" />}
                    label="United Kingdom"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.CA}
                    control={<Radio color="primary" />}
                    label="Canada"
                />
                <FormControlLabel
                    value={EditorCollateralDestination.AU}
                    control={<Radio color="primary" />}
                    label="Australia"
                />
            </RadioGroup>
        </FormControl>
    );
};

const SelectCollateral = ({
    value,
    setValue,
}: {
    value: EditorCollateral;
    setValue: (v: EditorCollateral) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Collateral Type</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorCollateral)}
            >
                <FormControlLabel
                    value={EditorCollateral.LETTER}
                    control={<Radio color="primary" />}
                    label="Letter"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_6X4}
                    control={<Radio color="primary" />}
                    label="Postcard (6x4)"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_9X6}
                    control={<Radio color="primary" />}
                    label="Postcard (9x6)"
                />
                <FormControlLabel
                    value={EditorCollateral.POSTCARD_11X6}
                    control={<Radio color="primary" />}
                    label="Postcard (11x6)"
                />
                <FormControlLabel
                    value={EditorCollateral['BIFOLD_SELF_MAILER_8.5x11']}
                    control={<Radio color="primary" />}
                    label="Bifold Self Mailer (8.5x11)"
                />
            </RadioGroup>
        </FormControl>
    );
};

const SelectSide = ({
    value,
    setValue,
}: {
    value: EditorTemplateSide;
    setValue: (v: EditorTemplateSide) => void;
}) => {
    return (
        <FormControl component="fieldset">
            <Typography>Select Template Side</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(_, v) => setValue(v as EditorTemplateSide)}
            >
                <FormControlLabel
                    value={EditorTemplateSide.FRONT}
                    control={<Radio color="primary" />}
                    label="Front"
                />
                <FormControlLabel
                    value={EditorTemplateSide.BACK}
                    control={<Radio color="primary" />}
                    label="Back"
                />
            </RadioGroup>
        </FormControl>
    );
};

const filter = <T extends EditorElementBase>(
    elem: Pick<
        T,
        'blurEnabled' | 'blurRadius' | 'brightnessEnabled' | 'brightness'
    >
) => {
    const blur = elem.blurEnabled ? elem.blurRadius : 0;
    const brightness = elem.brightnessEnabled ? 1 + elem.brightness : 1;
    return `filter:
        blur(${blur}px)
        brightness(${brightness});`;
};

/**
 * Return values for `left`, `top`, `width`, `height`
 */
const sizeAndPosition = <T extends EditorElementBase>(
    elem: Pick<T, 'y' | 'x' | 'width' | 'height'>
) => {
    return `
        left: ${elem.x}px;
        top: ${elem.y}px;
        width: ${elem.width}px;
        height: ${elem.height}px;`;
};

const dropShadow = <T extends EditorElementBase>(
    elem: T,
    fn: (elem: T) => string
) => {
    if (elem.shadowEnabled) {
        const shadow = `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`;
        return `<div style="filter: ${shadow};">
            ${fn(elem)}
        </div>`;
    }

    return fn(elem);
};

// TODO: Make indentation of HTML pretty
const imageHTML = (elem: EditorImageElement) => {
    const imgMT = -elem.cropY * (elem.height / elem.cropHeight);
    const imgML = -elem.cropX * (elem.width / elem.cropWidth);
    const imgW = elem.width / elem.cropWidth;
    const imgH = elem.height / elem.cropHeight;

    const img = `<img
                src="${elem.src}"
                style="
                    width: ${imgW}px;
                    height: ${imgH}px;
                    position: absolute;
                    margin: 0;
                    padding: 0;
                    left: 0;
                    top: 0;
                    margin-top: ${imgMT}px;
                    margin-left: ${imgML}px;
                    opacity: ${elem.opacity};
                    ${filter(elem)}
                "
            />`;

    const border = elem.borderSize
        ? `<div style="
                position: absolute;
                width: ${elem.width}px;
                height: ${elem.height}px;
                border: ${elem.borderSize}px solid ${elem.borderColor};
            "></div>`
        : '';

    const scaleX = `scaleX(${elem.flipX ? -1 : 1})`;
    const scaleY = `scaleY(${elem.flipY ? -1 : 1})`;
    const rotate = `rotate(${elem.rotation}deg)`;
    const container = `
        <div
            style="
                position: absolute;
                margin: 0;
                padding: 0;
                ${sizeAndPosition(elem)}
                clip: rect(0px, ${elem.width}, ${elem.height}, 0px);
                overflow: visible;
                transform: ${rotate} ${scaleX} ${scaleY};
                transform-origin: top left;
            "
        >
            ${img}
            ${border}
        </div>
    `;

    return container;
};

export type EditTemplateProps = {
    fullScreen: boolean;
    updatedDescription?: string;
};

class TemplatesServiceWithID {
    constructor(private service: TemplatesService, private id: string) {}

    async get() {
        return await this.service.get(this.id);
    }

    async update(params: TemplateUpdateParams) {
        return await this.service.update(this.id, params);
    }
}

class Service {
    constructor(
        private templatesService?: TemplatesServiceWithID,
        public sessionService?: SingleSessionService
    ) {
        if (!this.templatesService && !this.sessionService) {
            throw new Error(
                'Editor service set up incorrectly. Missing both templates and session service.'
            );
        }
    }

    async getDetails() {
        if (this.sessionService) {
            const details = await this.sessionService.getWithDetails();

            return {
                template: details.template,
                backURL: details.backURL,
                title: details.title,
            };
        }

        return {
            template: await this.templatesService!.get(),
        };
    }

    async update(params: TemplateUpdateParams) {
        if (this.sessionService) {
            return await this.sessionService.updateTemplate(params);
        }

        return await this.templatesService!.update(params);
    }
}

const flattenEditorChildren = (
    children: EditorElement[]
): Exclude<EditorElement, EditorGroupElement>[] =>
    children.flatMap((element) => {
        if (element.type !== 'group') {
            return [element];
        }

        return flattenEditorChildren(element.children);
    });

const useEditorService = (params: {
    templateID?: string;
    sessionID?: string;
}) => {
    const templateService = useTemplatesService();

    return useMemo(() => {
        if (params.templateID) {
            return new Service(
                new TemplatesServiceWithID(templateService, params.templateID)
            );
        }

        return new Service(
            undefined,
            new SingleSessionService(params.sessionID!)
        );
    }, [params, templateService]);
};

const EditTemplate = forwardRef(
    ({ fullScreen, updatedDescription }: EditTemplateProps, ref) => {
        const { createOrderOrigin } = useCreateOrderOrigin();

        const params = useParams<{
            templateID: string;
            sessionID?: string;
        }>();

        const imagesService = useImagesService();
        const uploadFunction = useCallback(
            async (file: File) => {
                try {
                    return await imagesService.uploadWithRetries(
                        file,
                        undefined,
                        {
                            'x-template-session-id': params.sessionID || '',
                        }
                    );
                } catch (err) {
                    // HACK(Apaar): Polotno doesn't document an error case for image uploads. Previously we were just depending on the
                    // entire editor being reloaded every time we notify an error, but that's naturally wrong.
                    //
                    // As such, we just show this invalid image every time an upload fails.
                    return 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/invalid_image.png';
                }
            },
            [imagesService, params]
        );

        useEffect(() => {
            setUploadFunc(uploadFunction);
        }, [uploadFunction]);

        const history = useHistory();

        const service = useEditorService(params);

        const { dispatch } = useContext(NotificationContext);

        const [sessionError, setSessionError] = useState<APIRequestError>();
        const [store, setStore] = useState<
            ReturnType<typeof createStore> | undefined
        >();
        const [template, setTemplate] = useState<Template>();

        const [backURL, setBackURL] = useState<string>();
        const [title, setTitle] = useState<string>();

        const [description, setDescription] = useState('');

        const [collateralDest, setCollateralDest] = useState(
            EditorCollateralDestination.US_INTL
        );

        const [initCollateral, setInitCollateral] = useState(
            EditorCollateral.LETTER
        );

        const [templateSide, setTemplateSide] = useState(
            EditorTemplateSide.FRONT
        );

        const [loading, setLoading] = useState(false);

        useEffect(() => {
            // Initialize the polotno store on mount
            setStore(
                createStore({
                    key: process.env.REACT_APP_POLOTNO_KEY!,
                    showCredit: false,
                })
            );

            (async () => {
                try {
                    setLoading(true);

                    const { template, backURL, title } =
                        await service.getDetails();

                    setTemplate(template);
                    setBackURL(backURL);
                    setTitle(title);

                    setDescription(
                        // FIXME(Apaar): We do `||` because `updatedDescription` is (mistakenly?) set to
                        // empty string instead of undefined when the editor is first loaded.
                        // This whole updateDescription thing is confusing anyways, should probably remove it.
                        (updatedDescription || template.description) ?? ''
                    );
                } catch (err) {
                    if (service.sessionService) {
                        setSessionError(err as APIRequestError);
                    }

                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })();
            // disabled since this is an onload function for template,
            // and we removed updatedDescription to limit the number of renders.
            //
            // HACK(Apaar): I also removed service from the following line as the service gets updated every
            // time a notification is kicked off (not good) and this reloads the entire editor. We don't want to do
            // that.
            //
            // FIXME(Apaar): We can't really depend on the `useMemo` in the base service to prevent re-renders (it's just
            // an optimization hook), so good to think about the _correct_ way of doing this.
            //
            // eslint-disable-next-line
        }, []);

        useEffect(() => {
            if (updatedDescription) {
                // Only modify the underlying description if there's actually an updatedDescription
                setDescription(updatedDescription);
            }
        }, [updatedDescription]);

        useEffect(() => {
            if (!template || !store || !template.metadata?.editorData) {
                return;
            }

            store.loadJSON(template.metadata.editorData);

            if (template.metadata.editorCollateral !== 'letter') {
                return;
            }

            const deregisterNewPageHandler = store.on('change', () => {
                store.history.ignore(() => {
                    for (const page of store.pages) {
                        if (page.children.length > 0) {
                            continue;
                        }

                        // Add guideline if no elements are present
                        // TODO(Apaar): Check if the page-sized guideline element is not present
                        // rather than just checking if children are empty or not
                        page.addElement({
                            type: GUIDELINE,
                            color: 'rgba(255, 0, 0, 0.3)',
                            x: 0.25 * PIXELS_PER_INCH,
                            y: 0.25 * PIXELS_PER_INCH,
                            width: store.width - 0.5 * PIXELS_PER_INCH,
                            height: store.height - 0.5 * PIXELS_PER_INCH,
                            selectable: false,
                            alwaysOnTop: true,
                            showInExport: false,
                        });
                    }

                    // TODO(Apaar): Figure out what this `skipStateReplacement` parameter does
                    // (The second argument to store.history.ignore). Since it's not documented
                    // I won't provide it.
                });
            });

            return () => {
                if (deregisterNewPageHandler) {
                    deregisterNewPageHandler();
                }
            };
        }, [template, store]);

        const initEditor = async () => {
            if (!template || !store) {
                return;
            }

            setLoading(true);

            switch (initCollateral) {
                case EditorCollateral.LETTER:
                    if (collateralDest === EditorCollateralDestination.UK) {
                        store.setSize(
                            8.3 * PIXELS_PER_INCH,
                            11.7 * PIXELS_PER_INCH
                        );
                    } else if (
                        collateralDest === EditorCollateralDestination.AU
                    ) {
                        // TODO(Apaar): Merge AU and UK collateral destination
                        store.setSize(
                            8.3 * PIXELS_PER_INCH,
                            11.7 * PIXELS_PER_INCH
                        );
                    } else {
                        store.setSize(
                            8.5 * PIXELS_PER_INCH,
                            11 * PIXELS_PER_INCH
                        );
                    }
                    break;

                case EditorCollateral.POSTCARD_6X4:
                    store.setSize(
                        6.25 * PIXELS_PER_INCH,
                        4.25 * PIXELS_PER_INCH
                    );
                    break;

                case EditorCollateral.POSTCARD_9X6:
                    store.setSize(
                        9.25 * PIXELS_PER_INCH,
                        6.25 * PIXELS_PER_INCH
                    );
                    break;

                case EditorCollateral.POSTCARD_11X6:
                    store.setSize(
                        11.25 * PIXELS_PER_INCH,
                        6.25 * PIXELS_PER_INCH
                    );
                    break;
                case EditorCollateral['BIFOLD_SELF_MAILER_8.5x11']:
                    // Adding a padding to the regular 8.5x11 for trimming purposes
                    store.setSize(
                        8.625 * PIXELS_PER_INCH,
                        11.125 * PIXELS_PER_INCH
                    );
                    break;
            }

            const page = store.addPage();

            if (initCollateral === EditorCollateral.LETTER) {
                let element: any;
                let w: number = 0;
                let h: number = 0;

                switch (collateralDest) {
                    case EditorCollateralDestination.US_INTL:
                        w = 8.5 * PIXELS_PER_INCH;
                        h = 11 * PIXELS_PER_INCH;
                        element = page.addElement({
                            type: 'image',
                            src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_letter_address_stamp_new.png',
                            locked: true,
                            x: 0.5 * PIXELS_PER_INCH,
                            y: 0.2 * PIXELS_PER_INCH,
                            width: 3.25 * PIXELS_PER_INCH,
                            height: 2.6 * PIXELS_PER_INCH,
                        });

                        break;

                    case EditorCollateralDestination.CA:
                        w = 8.5 * PIXELS_PER_INCH;
                        h = 11 * PIXELS_PER_INCH;
                        element = page.addElement({
                            type: 'image',
                            src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_letter_address_stamp.png',
                            locked: true,
                            x: 1.375 * PIXELS_PER_INCH,
                            y: 0.75 * PIXELS_PER_INCH,
                            width: 2.5 * PIXELS_PER_INCH,
                            height: 2.5 * PIXELS_PER_INCH,
                        });

                        break;

                    case EditorCollateralDestination.UK:
                        w = 8.3 * PIXELS_PER_INCH;
                        h = 11.7 * PIXELS_PER_INCH;
                        element = page.addElement({
                            type: 'image',
                            src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_letter_address_stamp.png',
                            locked: true,
                            x: 0.787 * PIXELS_PER_INCH,
                            y: 1.54 * PIXELS_PER_INCH,
                            width: 3.54 * PIXELS_PER_INCH,
                            height: 1.77 * PIXELS_PER_INCH,
                        });

                        break;

                    case EditorCollateralDestination.AU:
                        w = 8.25 * PIXELS_PER_INCH;
                        h = 11.75 * PIXELS_PER_INCH;
                        element = page.addElement({
                            type: 'image',
                            src: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/au_letter_address_stamp.png',
                            locked: true,
                            x: 1.26 * PIXELS_PER_INCH,
                            y: 2.2 * PIXELS_PER_INCH,
                            width: 3.74 * PIXELS_PER_INCH,
                            height: 1.1 * PIXELS_PER_INCH,
                        });

                        break;
                }

                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(255, 0, 0, 0.3)',
                    x: 0.25 * PIXELS_PER_INCH,
                    y: 0.25 * PIXELS_PER_INCH,
                    width: w - 0.5 * PIXELS_PER_INCH,
                    height: h - 0.5 * PIXELS_PER_INCH,
                });

                if (element) {
                    element.set({ custom: { skipHTML: true } });
                }
            } else if (isPostcardCollateral(initCollateral)) {
                let elementPrimary: any;
                let elementSecondary: any;
                let w: number = 0;
                let h: number = 0;

                const offset = 0.125;

                const render = ({
                    isPostcardBack,
                    urlPrimaryUS,
                    urlSecondaryUS,
                    xPrimaryValueUS,
                    yPrimaryValueUS,
                    widthPrimaryValueUS,
                    heightPrimaryValueUS,
                    xSecondaryValueUS,
                    ySecondaryValueUS,
                    widthSecondaryValueUS,
                    heightSecondaryValueUS,
                    urlPrimaryCA,
                    urlSecondaryCA,
                    xPrimaryValueCA,
                    yPrimaryValueCA,
                    widthPrimaryValueCA,
                    heightPrimaryValueCA,
                    xSecondaryValueCA,
                    ySecondaryValueCA,
                    widthSecondaryValueCA,
                    heightSecondaryValueCA,
                    urlPrimaryUK,
                    xPrimaryValueUK,
                    yPrimaryValueUK,
                    widthPrimaryValueUK,
                    heightPrimaryValueUK,
                }: RenderData) => {
                    if (!isPostcardBack) {
                        return;
                    }

                    switch (collateralDest) {
                        case EditorCollateralDestination.US_INTL:
                            elementPrimary = page.addElement({
                                type: 'image',
                                src: urlPrimaryUS,
                                locked: true,
                                x: (xPrimaryValueUS - offset) * PIXELS_PER_INCH,
                                y: (yPrimaryValueUS - offset) * PIXELS_PER_INCH,
                                width: widthPrimaryValueUS * PIXELS_PER_INCH,
                                height: heightPrimaryValueUS * PIXELS_PER_INCH,
                            });

                            elementSecondary = page.addElement({
                                type: 'image',
                                src: urlSecondaryUS,
                                locked: true,
                                x:
                                    (xSecondaryValueUS - offset) *
                                    PIXELS_PER_INCH,
                                y:
                                    (ySecondaryValueUS - offset) *
                                    PIXELS_PER_INCH,
                                width: widthSecondaryValueUS * PIXELS_PER_INCH,
                                height:
                                    heightSecondaryValueUS * PIXELS_PER_INCH,
                            });
                            break;
                        case EditorCollateralDestination.CA:
                            elementPrimary = page.addElement({
                                type: 'image',
                                src: urlPrimaryCA,
                                locked: true,
                                x: (xPrimaryValueCA - offset) * PIXELS_PER_INCH,
                                y: (yPrimaryValueCA - offset) * PIXELS_PER_INCH,
                                width: widthPrimaryValueCA * PIXELS_PER_INCH,
                                height: heightPrimaryValueCA * PIXELS_PER_INCH,
                            });

                            elementSecondary = page.addElement({
                                type: 'image',
                                src: urlSecondaryCA,
                                locked: true,
                                x:
                                    (xSecondaryValueCA - offset) *
                                    PIXELS_PER_INCH,
                                y:
                                    (ySecondaryValueCA - offset) *
                                    PIXELS_PER_INCH,
                                width: widthSecondaryValueCA * PIXELS_PER_INCH,
                                height:
                                    heightSecondaryValueCA * PIXELS_PER_INCH,
                            });
                            break;
                        case EditorCollateralDestination.UK:
                            elementPrimary = page.addElement({
                                type: 'image',
                                src: urlPrimaryUK,
                                locked: true,
                                x: (xPrimaryValueUK + offset) * PIXELS_PER_INCH,
                                y: (yPrimaryValueUK + offset) * PIXELS_PER_INCH,
                                width: widthPrimaryValueUK * PIXELS_PER_INCH,
                                height: heightPrimaryValueUK * PIXELS_PER_INCH,
                            });
                            break;
                    }

                    elementPrimary &&
                        elementPrimary.set({ custom: { skipHTML: true } });
                    elementSecondary &&
                        elementSecondary.set({ custom: { skipHTML: true } });
                };

                switch (initCollateral) {
                    case EditorCollateral.POSTCARD_6X4:
                        w = 6 * PIXELS_PER_INCH;
                        h = 4 * PIXELS_PER_INCH;

                        render({
                            isPostcardBack:
                                templateSide === EditorTemplateSide.BACK,
                            urlPrimaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_6x4_address_indicia_stamp.png',
                            urlSecondaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                            xPrimaryValueUS: 6.25 - 2.4,
                            yPrimaryValueUS: 2 * offset,
                            widthPrimaryValueUS: 2.4,
                            heightPrimaryValueUS: 4,
                            xSecondaryValueUS: 6.25 - 4.75,
                            ySecondaryValueUS: 4.25 - 0.625,
                            widthSecondaryValueUS: 4.75,
                            heightSecondaryValueUS: 0.625,
                            urlPrimaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                            urlSecondaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_6x4_receiver_address_stamp.png',
                            xPrimaryValueCA: 2 * offset,
                            yPrimaryValueCA: 2 * offset,
                            widthPrimaryValueCA: 2.063,
                            heightPrimaryValueCA: 1.57,
                            xSecondaryValueCA: 6.25 - 2.4,
                            ySecondaryValueCA: 2 * offset,
                            widthSecondaryValueCA: 2.4,
                            heightSecondaryValueCA: 4,
                            urlPrimaryUK:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_6x4_ink_free_zone.png',
                            xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            yPrimaryValueUK: 0,
                            widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            heightPrimaryValueUK: h / PIXELS_PER_INCH,
                        });

                        break;

                    case EditorCollateral.POSTCARD_9X6:
                        w = 9 * PIXELS_PER_INCH;
                        h = 6 * PIXELS_PER_INCH;

                        render({
                            isPostcardBack:
                                templateSide === EditorTemplateSide.BACK,
                            urlPrimaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_9x6_11x6_address_indicia_stamp.png',
                            urlSecondaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                            xPrimaryValueUS: 9.25 - 3.6,
                            yPrimaryValueUS: 2 * offset,
                            widthPrimaryValueUS: 3.6,
                            heightPrimaryValueUS: 6,
                            xSecondaryValueUS: 9.25 - 4.75,
                            ySecondaryValueUS: 6.25 - 0.625,
                            widthSecondaryValueUS: 4.75,
                            heightSecondaryValueUS: 0.625,
                            urlPrimaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                            urlSecondaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_9x6_11x6_receiver_address_stamp.png',
                            xPrimaryValueCA: 2 * offset,
                            yPrimaryValueCA: 2 * offset,
                            widthPrimaryValueCA: 2.063,
                            heightPrimaryValueCA: 1.57,
                            xSecondaryValueCA: 9.25 - 3.6,
                            ySecondaryValueCA: 2 * offset,
                            widthSecondaryValueCA: 3.6,
                            heightSecondaryValueCA: 6,
                            urlPrimaryUK:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_9x6_ink_free_zone.png',
                            xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            yPrimaryValueUK: 0,
                            widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            heightPrimaryValueUK: h / PIXELS_PER_INCH,
                        });

                        break;

                    case EditorCollateral.POSTCARD_11X6:
                        w = 11 * PIXELS_PER_INCH;
                        h = 6 * PIXELS_PER_INCH;

                        render({
                            isPostcardBack:
                                templateSide === EditorTemplateSide.BACK,
                            urlPrimaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_9x6_11x6_address_indicia_stamp.png',
                            urlSecondaryUS:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/us_postcard_barcode_stamp.png',
                            xPrimaryValueUS: 11.25 - 3.6,
                            yPrimaryValueUS: 2 * offset,
                            widthPrimaryValueUS: 3.6,
                            heightPrimaryValueUS: 6,
                            xSecondaryValueUS: 11.25 - 4.75,
                            ySecondaryValueUS: 6.25 - 0.625,
                            widthSecondaryValueUS: 4.75,
                            heightSecondaryValueUS: 0.625,
                            urlPrimaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_return_address_stamp.png',
                            urlSecondaryCA:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/ca_postcard_9x6_11x6_receiver_address_stamp.png',
                            xPrimaryValueCA: 2 * offset,
                            yPrimaryValueCA: 2 * offset,
                            widthPrimaryValueCA: 2.063,
                            heightPrimaryValueCA: 1.57,
                            xSecondaryValueCA: 11.25 - 3.6,
                            ySecondaryValueCA: 2 * offset,
                            widthSecondaryValueCA: 3.6,
                            heightSecondaryValueCA: 6,
                            urlPrimaryUK:
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/uk_postcard_11x6_ink_free_zone.png',
                            xPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            yPrimaryValueUK: 0,
                            widthPrimaryValueUK: (0.5 * w) / PIXELS_PER_INCH,
                            heightPrimaryValueUK: h / PIXELS_PER_INCH,
                        });

                        break;
                }

                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(60, 179, 113, 0.3)',
                    x: 0.125 * PIXELS_PER_INCH,
                    y: 0.125 * PIXELS_PER_INCH,
                    width: w,
                    height: h,
                });

                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(255, 0, 0, 0.3)',
                    x: 0.25 * PIXELS_PER_INCH,
                    y: 0.25 * PIXELS_PER_INCH,
                    width: w - 0.25 * PIXELS_PER_INCH,
                    height: h - 0.25 * PIXELS_PER_INCH,
                });
            } else {
                // Self mailers
                const trimPadding = 0.125 * PIXELS_PER_INCH;
                const height = 11 * PIXELS_PER_INCH;
                const width = 8.5 * PIXELS_PER_INCH;
                const margin = 0.188 * PIXELS_PER_INCH;
                const trim = 0.0625 * PIXELS_PER_INCH;
                const foldLinePos = 5.625 * PIXELS_PER_INCH; // 5.5" from top/bottom after trimming

                // Guidlines
                //
                // Trim of 0.0625" all around
                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(60, 179, 113, 0.3)', // Green-ish
                    x: trim,
                    y: trim,
                    width,
                    height,
                });

                // Margin of 0.188"
                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(255, 0, 0, 0.3)', // Red
                    x: margin,
                    y: margin,
                    // Padding is all around, cut it in half to account for our
                    // x/y position already
                    width: width - trimPadding / 2 - margin,
                    height: height - trimPadding / 2 - margin,
                });

                // Fold line
                page.addElement({
                    type: GUIDELINE,
                    color: 'rgba(0, 0, 0, 1)', // Black
                    x: 0,
                    y: foldLinePos,
                    width: width + trimPadding,
                    height: 0 * PIXELS_PER_INCH,
                });

                if (templateSide === EditorTemplateSide.FRONT) {
                    const addressStampURL =
                        'https://pg-prod-bucket-1.s3.amazonaws.com/assets/self_mailer_us_intl_address_zone.png';
                    const stampWidth = 4 * PIXELS_PER_INCH;
                    const stampHeight = 2.5 * PIXELS_PER_INCH;
                    const stampTopPos = 2.4 * PIXELS_PER_INCH;
                    const stampLeftPos = width - stampWidth;

                    const image = page.addElement({
                        type: 'image',
                        src: addressStampURL,
                        locked: true,
                        x: stampLeftPos + trimPadding,
                        y: stampTopPos + trimPadding,
                        width: stampWidth,
                        height: stampHeight,
                    });

                    image.set({ custom: { skipHTML: true } });

                    // There is also a margin surrounding the external fold line
                    page.addElement({
                        type: GUIDELINE,
                        color: 'rgba(255, 0, 0, 0.3)', // Red
                        x: 0,
                        y: foldLinePos - margin,
                        width: width + trimPadding,
                        height: margin * 2,
                    });
                }
            }

            setTemplate(
                await service.update({
                    description,
                    metadata: {
                        ...template.metadata,
                        editorData: store.toJSON(),
                        editorCollateralDest: collateralDest,
                        editorCollateral: initCollateral,
                        editorPostcardSide:
                            initCollateral !== EditorCollateral.LETTER
                                ? templateSide
                                : undefined,
                    },
                })
            );

            setLoading(false);
        };

        const saveTemplate = async () => {
            if (!template || !store) {
                return;
            }

            const editorData = store.toJSON() as unknown as EditorData;
            const fontFamilies = new Set<string>();
            const customFonts = new Map<string, string>();
            const innerHTML = [];

            await Promise.all(
                editorData.fonts.map(async ({ fontFamily, styles, url }) => {
                    let src = url;

                    if (src.startsWith('data')) {
                        src = await imagesService.uploadWithRetries(
                            url,
                            undefined,
                            { 'x-template-session-id': params.sessionID || '' }
                        );

                        store.removeFont(fontFamily);
                        store.addFont({ fontFamily, styles, url: src });
                    }

                    customFonts.set(fontFamily, src);
                })
            );

            for (const page of editorData.pages) {
                const pageHTML = [];

                const flattenedChildren = flattenEditorChildren(page.children);

                for (const elem of flattenedChildren) {
                    if (elem.custom?.skipHTML || elem.name === 'grid') {
                        continue;
                    }

                    switch (elem.type) {
                        case 'text':
                            fontFamilies.add(elem.fontFamily);

                            pageHTML.push(`
                            <div style="
                                position: absolute;

                                left: ${elem.x}px;
                                top: ${elem.y}px;

                                transform: rotate(${elem.rotation}deg);
                                transform-origin: top left;

                                width: ${elem.width}px;
                                height: ${elem.height}px;

                                font-family: '${elem.fontFamily}';
                                font-size: ${elem.fontSize}px;
                                font-style: ${elem.fontStyle};
                                font-weight: ${elem.fontWeight};

                                color: ${elem.fill};
                                opacity: ${elem.opacity};

                                text-align: ${elem.align};

                                line-height: ${elem.lineHeight};
                                letter-spacing: ${elem.letterSpacing}em;
                                text-decoration: ${
                                    elem.textDecoration || 'none'
                                };
                                white-space: pre-wrap;
                            ">${elem.text.replace(/[\n\r]/gi, '<br>')}</div>`);
                            break;

                        case 'line':
                            const svg = `<svg 
                                        width="${elem.width}"
                                        height="${elem.height}"
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <line
                                            x1='0%'
                                            y1='50%'
                                            x2='100%'
                                            y2='50%'
                                            stroke-dasharray='${elem.dash
                                                .map((val) => val * elem.height)
                                                .join(',')}'
                                            stroke='${elem.color}'
                                            style="stroke-width:${
                                                elem.height
                                            }px;"
                                        />
                                    </svg>`;

                            const svgURL = await (async () => {
                                const localFile = new File([svg], 'image.svg', {
                                    type: 'image/svg+xml',
                                });

                                try {
                                    return await uploadFunction(localFile);
                                } catch (e) {
                                    console.error(e);
                                    return null;
                                }
                            })();

                            pageHTML.push(`
                                <div style="
                                    position: absolute;

                                    margin: 0;
                                    padding: 0;

                                    left: ${elem.x}px;
                                    top: ${elem.y}px;

                                    width: ${elem.width}px;
                                    height: ${elem.height}px;

                                    overflow: hidden;

                                    transform: rotate(${elem.rotation}deg);

                                    transform-origin: top left;
                                ">

                                <img src="${svgURL}" style="
                                    position: absolute;

                                    margin: 0;
                                    padding: 0;

                                    left: 0;
                                    top: 0;

                                    width: ${elem.width}px;
                                    height: ${elem.height}px;

                                    opacity: ${elem.opacity};

                                    filter:
                                        blur(${
                                            elem.blurEnabled
                                                ? elem.blurRadius
                                                : 0
                                        }px)
                                        brightness(${
                                            elem.brightnessEnabled
                                                ? 1 + elem.brightness
                                                : 1
                                        })
                                        ${
                                            elem.shadowEnabled
                                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                                : ''
                                        };
                                " />
                            </div>`);

                            break;
                        case 'svg':
                            const svgString = await urlToString(elem.src);

                            const replaceMap = new Map(
                                Object.entries(elem.colorsReplace || {})
                            );

                            const newSrc = replaceColors(
                                elem.keepRatio
                                    ? svgString
                                    : svgString.replaceAll(
                                          '<svg',
                                          '<svg preserveAspectRatio="none"'
                                      ),
                                replaceMap
                            );

                            const src =
                                newSrc.startsWith('data:') &&
                                newSrc.length > MAX_DATA_URL_SIZE
                                    ? await (async () => {
                                          const newSVGString =
                                              await urlToString(newSrc);
                                          const localFile = new File(
                                              [newSVGString],
                                              'image.svg',
                                              {
                                                  type: 'image/svg+xml',
                                              }
                                          );

                                          try {
                                              return await uploadFunction(
                                                  localFile
                                              );
                                          } catch (e) {
                                              console.error(e);
                                              return null;
                                          }
                                      })()
                                    : newSrc;

                            // Image failed to upload
                            if (!src) {
                                break;
                            }

                            // Also replace src in metadata before upload
                            if (
                                elem.src.startsWith('data:') &&
                                elem.src.length > MAX_DATA_URL_SIZE
                            ) {
                                const storePage = store.pages.find(
                                    (page_: any) => page_.id === page.id
                                );
                                const storeElem: SVGElementType =
                                    storePage!.children.find(
                                        (elem_: any) => elem_.id === elem.id
                                    );

                                const localFile = new File(
                                    [svgString],
                                    'image.svg',
                                    {
                                        type: 'image/svg+xml',
                                    }
                                );

                                try {
                                    storeElem.set({
                                        src: await uploadFunction(localFile),
                                    });
                                } catch (e) {
                                    console.error(e);
                                }
                            }

                            // For now (Polotno ^0.27.0), no cropping allowed.
                            pageHTML.push(`
                            <div style="
                                position: absolute;

                                margin: 0;
                                padding: 0;

                                left: ${elem.x}px;
                                top: ${elem.y}px;

                                width: ${elem.width}px;
                                height: ${elem.height}px;

                                overflow: hidden;

                                transform: rotate(${elem.rotation}deg) scaleX(${
                                elem.flipX ? -1 : 1
                            }) scaleY(${elem.flipY ? -1 : 1});

                                transform-origin: top left;
                            ">

                                <img src="${src}" style="
                                    position: absolute;

                                    margin: 0;
                                    padding: 0;

                                    left: 0;
                                    top: 0;

                                    width: ${elem.width}px;
                                    height: ${elem.height}px;

                                    opacity: ${elem.opacity};

                                    filter:
                                        blur(${
                                            elem.blurEnabled
                                                ? elem.blurRadius
                                                : 0
                                        }px)
                                        brightness(${
                                            elem.brightnessEnabled
                                                ? 1 + elem.brightness
                                                : 1
                                        })
                                        ${
                                            elem.shadowEnabled
                                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                                : ''
                                        };
                                " />
                                ${
                                    elem.borderSize
                                        ? `<div style="
                                                position: absolute;

                                                width: ${elem.width}px;
                                                height: ${elem.height}px;

                                                border: ${elem.borderSize}px solid ${elem.borderColor}
                                            ">
                                </div>`
                                        : ''
                                }
                            </div>`);
                            break;

                        case 'image':
                            pageHTML.push(dropShadow(elem, imageHTML));
                            break;

                        case MERGE_VARIABLE_IMAGE: {
                            pageHTML.push(`
                <div style="
                    position: absolute;

                    margin: 0;
                    padding: 0;

                    left: ${elem.x}px;
                    top: ${elem.y}px;

                    width: ${elem.width}px;
                    height: ${elem.height}px;

                    overflow: visible;

                    transform: rotate(${elem.rotation}deg);

                    transform-origin: top left;
                    ">

                    <img src="{{${elem.mergeVar}}}"
                        style="
                        position: absolute;

                        margin: 0;
                        padding: 0;

                        left: 0;
                        top: 0;

                        ${
                            elem.maintainAspectRatio
                                ? 'object-fit: contain;'
                                : ''
                        }
                        width: ${elem.width}px;
                        height: ${elem.height}px;

                        opacity: ${elem.opacity};

                        filter: blur(${
                            elem.blurEnabled ? elem.blurRadius : 0
                        }px) brightness(${
                                elem.brightnessEnabled ? 1 + elem.brightness : 1
                            })
                        ${
                            elem.shadowEnabled
                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                : ''
                        };"
                    />
                </div>`);
                            break;
                        }
                        case TRACKER_IMAGE: {
                            const trackerElement = `<img src="{{${
                                elem.trackerID
                            }.qrcode}}"
                        style="
                        position: absolute;

                        margin: 0;
                        padding: 0;

                        left: 0;
                        top: 0;

                        width: ${elem.size}px;
                        height: ${elem.size}px;

                        opacity: ${elem.opacity};

                        filter: blur(${
                            elem.blurEnabled ? elem.blurRadius : 0
                        }px) brightness(${
                                elem.brightnessEnabled ? 1 + elem.brightness : 1
                            })
                        ${
                            elem.shadowEnabled
                                ? `drop-shadow(0 0 ${elem.shadowBlur}px ${elem.shadowColor})`
                                : ''
                        };"
                    />`;

                            pageHTML.push(`
                                <div style="
                                    position: absolute;

                                    margin: 0;
                                    padding: 0;

                                    left: ${elem.x}px;
                                    top: ${elem.y}px;

                                    width: ${elem.size}px;
                                    height: ${elem.size}px;

                                    overflow: visible;

                                    transform: rotate(${elem.rotation}deg);

                                    transform-origin: top left;
                                    ">
                                    ${trackerElement}
                                    </div>`);
                        }
                    }
                }

                let backgroundStyle = '';

                if (page.background.includes('http')) {
                    backgroundStyle = `style="background: url(${page.background}); background-size: auto;"`;
                } else {
                    backgroundStyle = `style="background-color: ${page.background}"`;
                }

                innerHTML.push(`
                <div class="page" ${backgroundStyle}>
                    ${pageHTML.join('\n')}
                </div>
            `);
            }

            // Filter out font families that are from custom font uploads first
            const fontLinks = Array.from(fontFamilies)
                .filter((f) => !customFonts.has(f))
                .map(
                    (f) =>
                        `<link
                    rel="stylesheet"
                    type="text/css"
                    href="https://fonts.googleapis.com/css?family=${encodeURIComponent(
                        f
                    )}" />`
                );

            const fontFaces: string[] = [];

            customFonts.forEach((url, fontFamily) => {
                fontFaces.push(`
                    @font-face {
                        font-family: "${fontFamily}";
                        src: url("${url}")                        
                    }
                `);
            });

            const html = `
        <html>
            <head>
                <style>
                    ${fontFaces.join('\n')}
                    * {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                    }

                    body {
                        width: ${store.width}px;
                        height: ${store.height}px;
                    }

                    .page {
                        position: relative;
                        width: ${store.width}px;
                        height: ${store.height}px;
                        overflow: hidden;

                        ${
                            template.metadata?.editorCollateral ===
                            EditorCollateral.LETTER
                                ? 'page-break-after: always'
                                : ''
                        }
                    }
                </style>

                ${fontLinks.join('\n')}
            </head>
            <body>
                ${innerHTML.join('\n')}
            </body>
        </html>`;

            setLoading(true);

            try {
                // Unable to mutate the `pages.children` array, so create a new one
                const storeJSON = store.toJSON() as unknown as EditorData;
                const pagesWithoutGrids = storeJSON.pages.map((page) => {
                    return {
                        ...page,
                        children: page.children.filter(
                            (child) => child.name !== 'grid'
                        ),
                    };
                });

                await service.update({
                    description,
                    html,
                    metadata: {
                        ...template.metadata,
                        // Update the editorData for changes
                        editorData: { ...storeJSON, pages: pagesWithoutGrids },
                    },
                });
            } catch (err) {
                console.error(err);
                if (service.sessionService) {
                    setSessionError(err as APIRequestError);
                }
                setLoading(false);
                return;
            }

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Saved template.',
            });

            setLoading(false);
            if (createOrderOrigin) {
                history.push(createOrderOrigin);
            }
        };

        useImperativeHandle(ref, () => ({
            saveTemplate,
        }));

        if (sessionError) {
            const replacedName = sessionError.name.replaceAll('_', ' ');
            const name = `${replacedName
                .charAt(0)
                .toLocaleUpperCase()}${replacedName.slice(1)}`;
            return <EmbedError title={name} body={sessionError.message} />;
        }

        // TODO Display loader
        return template && store ? (
            <>
                {fullScreen && !service.sessionService && <TopNav />}

                <GridPaper
                    direction="column"
                    spacing={fullScreen ? 2 : 0}
                    style={
                        fullScreen && service.sessionService
                            ? // HACK:
                              // MUI applies a width of `calc(100% + 16px)`
                              // and a margin of `-8px` for a spacing of `2`.
                              // This leads to some weird scrolling behaviour
                              // and a width greater than the viewport for
                              // true full screen viewing.
                              { width: '100%', margin: '0px' }
                            : undefined
                    }
                >
                    {fullScreen && (
                        <Grid item xs={12}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Typography variant="h5">
                                        Edit Template -{' '}
                                        {title ?? template.description}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container spacing={1}>
                                        {/* We only present the back button if a backURL is provided.
                                            This enables iframes to have the editor be presented without a back button. */}
                                        {(!service.sessionService ||
                                            backURL) && (
                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        if (backURL) {
                                                            window.location.href =
                                                                backURL;
                                                        } else {
                                                            history.push(
                                                                `/dashboard/templates/${template.id}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {template.metadata
                                                        ?.editorData
                                                        ? 'Back to Template Details'
                                                        : 'Switch to HTML Editor'}
                                                </Button>
                                            </Grid>
                                        )}

                                        {template.metadata?.editorData && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={saveTemplate}
                                                    disabled={
                                                        loading || !template
                                                    }
                                                >
                                                    Save Design
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        {template.metadata?.editorData ? (
                            <Box height="700px">
                                <PolotnoContainer store={store}>
                                    <SidePanelWrap>
                                        <SidePanel
                                            store={store}
                                            sections={[
                                                TextSection,
                                                ElementsSection,
                                                EditorImagesSection,
                                                GridSection,
                                                UploadSection,
                                                TrackersSection,
                                                MergeVariableSection,
                                                BackgroundSection,
                                            ]}
                                        />
                                    </SidePanelWrap>
                                    <WorkspaceWrap>
                                        <Toolbar store={store} hideLock />
                                        <Workspace
                                            store={store}
                                            pageControlsEnabled={
                                                template.metadata
                                                    ?.editorCollateral ===
                                                EditorCollateral.LETTER
                                            }
                                        />
                                        <ZoomButtons store={store} />
                                    </WorkspaceWrap>
                                </PolotnoContainer>
                            </Box>
                        ) : (
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <SelectDest
                                        value={collateralDest}
                                        setValue={setCollateralDest}
                                    />
                                </Grid>

                                <Grid item>
                                    <SelectCollateral
                                        value={initCollateral}
                                        setValue={setInitCollateral}
                                    />
                                </Grid>

                                {initCollateral !== EditorCollateral.LETTER && (
                                    <Grid item>
                                        <SelectSide
                                            value={templateSide}
                                            setValue={setTemplateSide}
                                        />
                                    </Grid>
                                )}

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={initEditor}
                                        disabled={loading}
                                    >
                                        Start Editing
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </GridPaper>
            </>
        ) : null;
    }
);

export default EditTemplate;
