import { useCallback } from 'react';

import useDeleteCampaignResources from './useDeleteCampaignResources';
import {
    draftSendAndWaitForReadyCampaign,
    useService as useCampaignService,
} from '../services/Campaigns';
import type { Contact } from '../services/Contacts';
import type { BulkCreationState } from '../components/CreateOrderControls';
import {
    type ContactOrMailingListData,
    isMailingListValue,
} from '../components/ContactOrCSV';

const useCreateOrders = () => {
    const campaignService = useCampaignService();
    const deleteCampaignResources = useDeleteCampaignResources();

    return useCallback(
        async (
            contactOrMailingListData: ContactOrMailingListData,
            creationStateCB: (state: {
                loading?: boolean;
                bulkCreationState?: BulkCreationState;
            }) => void,
            profileCB: () => Promise<{
                profileID: string;
                sendDate: Date;
                defaultSenderID?: string;
            }>,
            orderCB: (contact: Contact) => Promise<void>
        ) => {
            if (!contactOrMailingListData) {
                throw new Error('Contact(s) are required for sending orders.');
            }

            if (isMailingListValue(contactOrMailingListData)) {
                creationStateCB({
                    loading: true,
                    bulkCreationState: { type: 'order_profile' },
                });
                const { profileID, defaultSenderID, sendDate } =
                    await profileCB();

                creationStateCB({
                    bulkCreationState: { type: 'campaign' },
                });

                const profileType = profileID.startsWith('letter')
                    ? 'letterProfile'
                    : profileID.startsWith('postcard')
                    ? 'postcardProfile'
                    : profileID.startsWith('cheque')
                    ? 'chequeProfile'
                    : 'selfMailerProfile';

                const campaign = await campaignService.create({
                    [profileType]: profileID,
                    mailingList: contactOrMailingListData.mailingList.id,
                    defaultSenderContact: defaultSenderID,
                    metadata: { postgrid_dashboard: '' },
                });

                try {
                    await draftSendAndWaitForReadyCampaign(
                        campaign.id,
                        sendDate,
                        campaignService
                    );
                } catch (err) {
                    await deleteCampaignResources(
                        campaign,
                        contactOrMailingListData.mailingListImport.id
                    );
                    creationStateCB({
                        bulkCreationState: {
                            type: 'error',
                            message: (err as Error).message,
                        },
                        loading: false,
                    });
                    throw err;
                }

                creationStateCB({
                    loading: false,
                });
            } else {
                creationStateCB({ loading: true });
                await orderCB(contactOrMailingListData);
                creationStateCB({ loading: false });
            }
        },
        [campaignService, deleteCampaignResources]
    );
};

export default useCreateOrders;
